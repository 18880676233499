window.addEventListener('load', function(event) {
    const clickButtons = document.querySelectorAll('.gta-button-click-event')
    const productOverview = document.querySelectorAll('.gta-product-overview-click-event')
    const productAccordion = document.querySelectorAll('.gta-product-accordion')
    const applicationFinder = document.querySelectorAll('.gta-application-finder')
    const productHighlight = document.querySelectorAll('.gta-tubag-product-highlight')
    const productFilterOverview = document.querySelectorAll('.gta-product-filter-overview')

    clickButtons.forEach((toggler) => {
        toggler.addEventListener('click', () => {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': 'buttonClick'
            });
        });
    })

    productHighlight.forEach((toggler) => {
        toggler.addEventListener('click', () => {
            if (toggler.getAttribute("href").includes('produkte')) {
                var href = toggler.getAttribute("href");
                var product = href.split("/").pop();
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'productHighlightClick',
                    'product' : product
                });
            }
        });
    })

    productHighlight.forEach((toggler) => {
        toggler.addEventListener('mouseenter', () => {
            if (toggler.getAttribute("href").includes('produkte')) {
                var href = toggler.getAttribute("href");
                var product = href.split("/").pop();
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'productHighlightView',
                    'product' : product
                });
            }
        });
    })

    productOverview.forEach((toggler) => {
        toggler.addEventListener('click', () => {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': 'productClick',
                'product' : toggler.lastChild.childNodes[1].firstChild.innerText
            });
        });
    })

    productOverview.forEach((toggler) => {
        toggler.addEventListener('mouseenter', () => {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': 'productView',
                'product' : toggler.lastChild.childNodes[1].firstChild.innerText
            });
        });
    })

    productAccordion.forEach((toggler) => {
        if (document.querySelector(".product-detail-page")) {
            toggler.addEventListener('click', () => {
                var title = document.querySelector(".sievert-logo-square");
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'productAcodition',
                    'product' : title.innerText
                });
            });
        }
    })

    applicationFinder.forEach((toggler) => {
        toggler.addEventListener('change', () => {
            var title = document.querySelector(".select-button");
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': 'dropDown',
                'item' : title.innerText
            });
        });
    })

    if(productFilterOverview.length > 0) {
        var products = document.querySelectorAll(".gta-product-overview-click-event");
        var productsNames = [];
        products.forEach(product => {
            productsNames.push(product.children[1].children[1].innerText);
        })
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'productList',
            'product' : productsNames
        });
    };

    productFilterOverview.forEach( (toggler) => {
        toggler.addEventListener('click', () => {
            var products = document.querySelectorAll(".gta-product-overview-click-event");
            var productsNames = [];
            products.forEach(product => {
                productsNames.push(product.children[1].children[1].innerText);
            })
            console.log(productsNames);
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': 'productList',
                'product' : productsNames
            });
        });
    })

});
